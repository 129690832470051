import React from 'react';
import _ from 'lodash';

import css from './SectionQuoteBar.module.css';

const SectionQuoteBar = props => {
  const { quotemode, footer_quotes } = props;

  const randQuote = _.isArray(footer_quotes) ? _.sample(footer_quotes) : null;
  return (
    <section id={css['quote-bar']}>
      <div id={css['novica-bar']} className={css.container}>
        <div className={css['novica-bar-logo']}>
          <p>in collaboration with</p>
          <p>
            <img
              src="/static/images/landing/full-small-2019.png"
              alt="NOVICA - Home Decor, Jewelry &amp; Gifts by Talented Artisans Worldwide"
              className={css.novicalogo}
              width="275"
              height="85"
            />
          </p>
        </div>
        <div id={css['novica-bar-quotes']}>
          {quotemode === 'inline' ? (
            <div className={`${css.quote} ${css.current}`}>
              <p className={css['quote-content']}>
                <span>
                  <span className={css.quotationmark}>&ldquo;</span>
                  {randQuote && randQuote.quote}
                  <span className={css.quotationmark}>&rdquo;</span>
                </span>
              </p>
              <p className={css['quote-by']}>{randQuote && randQuote.by}</p>
            </div>
          ) : (
            <div className={css.quote}></div>
          )}
        </div>
      </div>
    </section>
  );
};

SectionQuoteBar.defaultProps = {};

SectionQuoteBar.propTypes = {};

export default SectionQuoteBar;
