import React, { useState } from "react";
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, Form } from '../../../components';
import {
  composeValidators,
  emailFormatValid,
  required,
} from '../../../util/validators';

import css from "../BecomeASellerFooterSection.module.css";

const BuyerSignupForm = props => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const { handleSubmit, invalid } = fieldRenderProps;
        return (
          <div className={css['buyers-signup-content']}>
            <div id={css['buyers-heading']}>
              <h4>Join the handmade movement</h4>
            </div>
            {isSubmitted ? (
              <div id={css['buyers-signup-form']} className={css.centered}>
                Thank you! We'll notify you when the movement starts.
              </div>
            ) : (
              <Form
                id={css['buyers-signup-form']}
                onSubmit={values => {
                  return handleSubmit(values).then(response => {
                    if (response) {
                      setIsSubmitted(true);
                    }
                  });
                }}
              >
                <p className={`${css['form-field']} ${css['input-firstname']}`}>
                  <FieldTextInput
                    id="firstName"
                    name="firstName"
                    className={`${css['buyer-firstname']} ${css['validate']}`}
                    placeholder="Your first name"
                    validate={required('Please enter your first name')}
                    isShowErrorTextAboveInput
                    errorTextClassName={css['error-text']}
                  />
                </p>
                <p className={`${css['form-field']} ${css['input-email']}`}>
                  <FieldTextInput
                    id="email"
                    name="email"
                    className={`${css['buyer-email']} ${css.validate}`}
                    placeholder="Your email"
                    validate={composeValidators(
                      required('Please enter your email'),
                      emailFormatValid('Please enter a valid email address')
                    )}
                    isShowErrorTextAboveInput
                    errorTextClassName={css['error-text']}
                  />
                </p>
                <p className={css['form-button']}>
                  <button
                    type="submit"
                    disabled={invalid}
                    className={`${css['buyer-submit']} ${css.button} button button-highlight1 button-animate button-animate-dark fixedwidth`}
                  >
                    Submit
                    <span
                      className={`${css.icon} material-symbols-outlined ${css['c-white']}`}
                    >
                      arrow_right_alt
                    </span>
                  </button>
                </p>
              </Form>
            )}
          </div>
        );
      }}
    />
  );
};

export default BuyerSignupForm;